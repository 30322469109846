<template>
  <div class="page-home-root">
    <base-header class="pb-6 pb-8 pt-6 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
      <b-row>
        <b-col xl="3" md="6">
          <stats-card title="申請数"
                      type="gradient-red"
                      :sub-title="task_statistics.total"
                      icon="far fa-hand-pointer"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">{{task_statistics.total_percentage}}%</span>
              <span class="text-nowrap">タスク数の割合</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="処理中数"
                      type="gradient-red"
                      :sub-title="task_statistics.unresolved"
                      icon="far fa-handshake"
                      class="mb-4"
                      :link="{name: 'tasks-unresolved'}">

            <template slot="footer">
              <span class="text-success mr-2">{{ task_statistics.unresolved_percentage }}%</span>
              <span class="text-nowrap">タスク数の割合</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="完了数"
                      type="gradient-red"
                      :sub-title="task_statistics.finished"
                      icon="fas fa-hand-paper"
                      class="mb-4"
                      :link="{name: 'tasks-finished'}">

            <template slot="footer">
              <span class="text-success mr-2">{{ task_statistics.finished_percentage }}%</span>
              <span class="text-nowrap">タスク数の割合</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="承認要数"
                      type="gradient-red"
                      :sub-title="task_statistics.approval"
                      icon="far fa-hand-point-right"
                      class="mb-4"
                      :link="{name: 'tasks-approval'}">

            <template slot="footer">
              <span class="text-success mr-2">{{ task_statistics.approval_percentage }}%</span>
              <span class="text-nowrap">タスク数の割合</span>
            </template>
          </stats-card>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--7" v-if="notices.length > 0">
      <b-row>
        <b-col>
          <b-overlay :show="loading" rounded="md">
            <b-card no-body>
              <b-card-header>
                社内お知らせ
              </b-card-header>
              <b-card-body>
                <b-card no-body class="mb-1 notice-card" v-for="(notice, key) in notices" :key="key">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <a v-b-toggle="`accordion-${key}`" variant="info">
                      <div>
                        {{ notice.title }}
                        <b-badge v-if="notice.is_new" variant="danger">new</b-badge>
                      </div>
                      <div class="date">{{ getNoticeDate(notice) }}</div>
                    </a>
                  </b-card-header>
                  <b-collapse :id="`accordion-${key}`" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <b-card-text><div v-html="notice.content_html"></div></b-card-text>
                      <b-list-group horizontal="md">
                        <b-list-group-item
                          v-for="(attachment, key) in notice.noticeattachment_set"
                          :key="key"
                          :href="get_notice_attachemnt_link(attachment)"
                          target="_blank"
                        >
                          <b-icon icon="file-earmark-text"></b-icon>
                          {{ attachment.name }}
                        </b-list-group-item>
                      </b-list-group>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </b-card-body>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { ajax, common, config } from '../../utils';
export default {
  data() {
    return {
      loading: true,
      task_statistics: {},
      notices: [],
    }
  },
  created: function () {
    document.title = common.getPageName(this.$route.name);
    this.getTaskStatistics();
    this.getNotices();
  },
  methods: {
    getTaskStatistics() {
      const vm = this;
      ajax.fetchGet(config.api.account.task_statistics).then(data => {
        vm.task_statistics = data;
      });
    },
    getNotices() {
      const vm = this;
      ajax.fetchGet(config.api.master.notice_list).then(data => {
        vm.notices = data;
      }).finally(() => {
        vm.loading = false;
      });
    },
    getNoticeDate(notice) {
      return common.formatDate(new Date(notice.created_dt), 'YYYY年MM月DD日')
    },
    get_notice_attachemnt_link(attachment) {
      return process.env.VUE_APP_API_URL + attachment.filepath
    }
  }
}
</script>